import React from 'react'
import video from '../images/video1.webm'
import ParticlesBackground from './ParticlesBackground'

function Hero() {
  return (
    <section className="hero px-4" id="hero">
      <div className="w-full relative bg-gradient-to-r from-violet-200 to-pink-200 rounded-2xl">
        <ParticlesBackground className="w-full h-screen" />
        <div className="container py-24 mt-[72px]">
          <div className="flex flex-wrap items-center">
            {/* hero content */}
            <div className="w-full lg:w-2/4 mb-8 text-center lg:text-left lg:mb-0">
              <h1 className="mb-4 font-semibold text-3xl lg:text-4xl" data-aos="fade-right"><span className='text-violet-600'>incard</span> eng qiyin muammolarning oson yechimi!</h1>
              <p className="mb-4 font-semibold" data-aos="fade-right" data-aos-delay="250">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam omnis dolore ducimus.</p>
              <a href='/#forma' className='py-4 inline-block px-8 font-semibold text-2xl bg-fuchsia-500 text-white leading-4 rounded-xl duration-300 ease-in-out hover:bg-fuchsia-600' data-aos="fade-right" data-aos-delay="500">Ulanish</a>
            </div>
            {/* hero video */}
            <div className="w-full lg:w-2/4" data-aos="fade-left" data-aos-delay="750">
              <div className="w-full mx-auto max-w-lg lg:max-w-none">
                <video loop autoPlay muted>
                  <source src={video} />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero