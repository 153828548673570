import React from 'react'

function Footer() {
  return (
    <footer className="footer py-4 bg-gray-800 text-white" id="footer">
      <div className="container">
        <div className="w-full text-center">
          <p>© 2022 inCard.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer