import React from 'react'
import { FcApproval } from 'react-icons/fc'

import image from '../images/3d2.png'
import icon1 from '../images/icons/1.png'
import icon2 from '../images/icons/2.png'
import Title from './Title'

function AboutUs() {
  return (
    <section className="about-us pb-24" id="about-us">
      <div className="container">
        <div className="flex flex-wrap gap-12 items-center">
          {/* about image */}
          <div className="w-full flex-auto lg:flex-1" data-aos="fade-right">
            <div className="w-full mx-auto max-w-lg lg:max-w-none">
              <img src={image} alt="404" />
            </div>
          </div>
          {/* about content */}
          <div className="w-full flex-auto lg:flex-1" data-aos="fade-left" data-aos-delay="250">
            {/* about title */}
            <Title text="Biz bilan siz oson yechimlarni topasiz!" />
            {/* about paragraphs */}
            <div className="w-full mb-12">
              <p className="mb-4">Bugun juda ko'plab mikrokredit tashkilotlari va banklar mijozlarga berilgan qarz mablag'larini va muddatli to'lov asosida tovarlarni realizatsiya qilayotgan firmalar tomonidan sotilgan tovarlarning muddati o'tgan qarzdorliklarini undirishda muammolarga duch kelmoqdalar.</p>
              <p className="">inCARD ushbu qarzlarni undirishda qulay yechim taklif qiladi.</p>
            </div>
            {/* about list */}
            <ul className="w-full">
              <li className="flex items-start mb-4">
                <div className=""><img src={icon1} alt="404" /></div>
                <p className='ml-4'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi quis et, ullam sapiente nulla assumenda.</p>
              </li>
              <li className="flex items-start">
              <div className=""><img src={icon2} alt="404" /></div>
                <p className='ml-4'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi quis et, ullam sapiente nulla assumenda.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs