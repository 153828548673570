import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './routes/Home';
import Layout from './routes/Layout';
import NoPage from './routes/NoPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />}></Route>
        </Route>
        <Route path='/*' element={<NoPage />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
