import React from 'react'

function Title(props) {
  return (
    <div className="w-full mb-10">
      <h1 className="font-semibold text-2xl text-violet-600 lg:text-3xl">{props.text}</h1>
    </div>
  )
}

export default Title