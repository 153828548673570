import React from 'react'
import Title from './Title'
import CountUp from 'react-countup';

function Counter() {
  return (
    <section className="counter p-4 pb-32" id="counter">
      {/* counter big container */}
      <div className="w-full py-16 bg-gradient-to-r from-violet-200 to-pink-200 rounded-2xl">
        {/* counter container */}
        <div className="container text-center">
          {/* counter title */}
          <div className="w-full" data-aos="fade-up">
            <Title text="Biz sifatli xizmatlarni o'z vaqtida taqdim etishdan faxrlanamiz" />
          </div>
          {/* counter content */}
          <div className="w-full flex flex-wrap justify-center gap-8">
            {/* counter item */}
            <div className="w-full lg:w-64" data-aos="fade-up" data-aos-delay="250">
              <h3 className='text-3xl font-semibold md:text-4xl text-gray-900'>
                <CountUp end={33} duration={2} delay={0} enableScrollSpy={true}>33</CountUp>+
              </h3>
              <p className='text-gray-800'>Ulangan banklar</p>
            </div>
            {/* counter item */}
            <div className="w-full lg:w-64" data-aos="fade-up" data-aos-delay="500">
              <h3 className='text-3xl font-semibold md:text-4xl text-gray-900'>
                <CountUp end={4} duration={2} delay={0} enableScrollSpy={true}>33</CountUp>x
              </h3>
              <p className='text-gray-800'>Effektivlik</p>
            </div>
            {/* counter item */}
            <div className="w-full lg:w-64" data-aos="fade-up" data-aos-delay="750">
              <h3 className='text-3xl font-semibold md:text-4xl text-gray-900'>
                <CountUp end={80} duration={2} delay={0} enableScrollSpy={true}>33</CountUp>%
              </h3>
              <p className='text-gray-800'>Vaqt tejaladi</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Counter