import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Outlet } from 'react-router-dom';
import { RiArrowUpLine } from 'react-icons/ri';
import { ScrollToTop } from 'react-simple-scroll-up';

import Footer from '../components/Footer';
import Header from '../components/Header';

AOS.init();

function Layout() {
  return (
    <>
      <div className="app">
        <Header />
        <Outlet />
        <ScrollToTop
          size="50"
          bgColor="rgb(255 255 255)"
          strokeFillColor="rgb(124 58 237)"
          strokeEmptyColor="rgb(237 233 254)"
          strokeWidth="2"
          symbol={<RiArrowUpLine />}
        />
        <Footer />
      </div>
    </>
  );
}

export default Layout