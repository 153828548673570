import React from 'react'
import AboutUs from '../components/AboutUs'
import Contact from '../components/Contact'
import Counter from '../components/Counter'
import Features from '../components/Features'
import Forma from '../components/Forma'
import Hero from '../components/Hero'

function Home() {
  return (
    <>
      <Hero />
      <Features />
      <AboutUs />
      <Counter />
      <Contact />
      <Forma />
    </>
  )
}

export default Home