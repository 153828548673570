import React from 'react'

function FeaturesCol(props) {
  return (
    <div className="w-full lg:w-1/3 md:w-1/2 px-4">
      <div className="group flex items-start">
        <div className="min-w-[3rem] h-12 flex items-center justify-center bg-violet-100 rounded-xl duration-300 ease-in-out group-hover:bg-violet-200">
          <h1 className='text-violet-600 text-xl'>{props.num}</h1>
        </div>
        <div className="inline-block ml-4 duration-300 ease-in-ou group-hover:text-violet-600">
          <p className="">{props.text || "-"}</p>
        </div>
      </div>
    </div>
  )
}

export default FeaturesCol