import React from 'react';

import { loadFull } from 'tsparticles';
import Particles from 'react-tsparticles';

import particlesConfig from './config/particlesConfig';

function ParticlesBackground() {
  const particlesInit = async (main) => await loadFull(main);

  return (
    <Particles id='particles' params={particlesConfig} init={particlesInit} />
  )
}

export default ParticlesBackground