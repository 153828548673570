import React from 'react'
import { Link } from 'react-router-dom'

function NoPage() {
  return (
    <section className="no-page" id="no-page">
      <div className="h-screen flex items-center justify-center text-center bg-gradient-to-r from-violet-200 to-pink-200">
        <div className="">
          <h1 className="mb-4 font-semibold text-3xl lg:text-4xl text-gray-600">Bunday sahifa mavjud emas!</h1>
          <Link to='/' className='font-semibold duration-300 ease-in-out text-violet-600'>Bosh sahifa</Link>
        </div>
      </div>
    </section>
  )
}

export default NoPage