import React from 'react'
import logo from '../images/incard-sm.png'

function Logo() {
  return (
    <div className="w-40">
      <img src={logo} alt="" />
    </div>
  )
}

export default Logo