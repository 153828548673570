import axios from 'axios';
import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser';

import InputMask from 'react-input-mask';

import image from '../images/3d3.png';
import Title from './Title';

function Forma() {
  const form = useRef(null)
  const [message, setMessage] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const submitButton = useRef(null)

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setMessage({ ...message, [name]: value });
    const copyMessage = { ...message, [name]: value };
    const phoneLength = copyMessage?.phone?.match(/[0-9]/g)?.length || 0;

    if (copyMessage.name && phoneLength === 12 && copyMessage.message) setIsDisabled(false)
    else setIsDisabled(true);
  };

  const getMessage = () => {
    let msg = '<b>inCard: Habar keldi!</b>\n\n'
    msg += `<b>Jo'natuvchi: </b> ${message?.name}\n`;
    msg += `<b>Telefon: </b> ${message?.phone}\n`;
    msg += `<b>Habar: </b> ${message?.message}\n`;

    return msg;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);
    submitButton.current.innerText = "Jo'natilmoqda...";

    const CHAT_ID = '-1001438588294';
    const TOKEN = '5666190378:AAGUdCs9h8SsXNU9Qpo_ASNvkwpLybbJMgw';
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    let isEmail = null;
    let isTelegram = null;

    // emailga jonatish
    await emailjs.sendForm('service_bbaf6vo', 'template_g5jlg14', form.current, 'CKDhUzdQbVIXCfPnH')
      .then(() => isEmail = true)
      .catch(() => isEmail = false);

    // telegramga jonatish
    await axios.post(URI_API, {
      chat_id: CHAT_ID,
      parse_mode: 'html',
      text: getMessage(),
    })
      .then(() => isTelegram = true)
      .catch(() => isTelegram = false);

    // animatsiya
    Swal.fire(
      "Habaringiz jo'natildi!",
      "Tez orada siz bilan bog'lanamiz",
      'success'
    )

    setIsDisabled(true);
    setMessage({});
    submitButton.current.innerText = "Jo'natish";
  }

  return (
    <section className="forma pb-24" id="forma" onSubmit={handleSubmit}>
      <div className="container">
        <div className="flex flex-wrap gap-12">
          {/* forma image */}
          <div className="flex-auto lg:flex-1" data-aos="fade-right">
            <div className="w-full mx-auto max-w-lg lg:max-w-none">
              <img src={image} alt="404" />
            </div>
          </div>
          {/* forma content */}
          <div className="flex-auto lg:flex-1" data-aos="fade-left" data-aos-delay="250">
            {/* forma title */}
            <Title text="Biz bilan bog'lanish!" />
            {/* forma form */}
            <form id='forma-form' className="w-full flex flex-wrap gap-4" ref={form}>
              <div className="w-full flex-auto lg:flex-1">
                <input type="text" name='name' required className="w-full py-2 px-4 rounded-lg border border-violet-200 focus:outline-none focus:border-violet-600" value={message.name || ''} placeholder='Ism...' onChange={handleChange} />
              </div>
              <div className="w-full flex-auto lg:flex-1">
                <InputMask mask="+\9\98(99) 999 99 99" type="text" name='phone' required className="w-full py-2 px-4 rounded-lg border border-violet-200 focus:outline-none focus:border-violet-600" value={message.phone || ''} placeholder='Telefon...' onChange={handleChange} />
              </div>
              <div className="w-full">
                <textarea name="message" required cols="30" rows="10" className="w-full py-2 px-4 rounded-lg border border-violet-200 focus:outline-none focus:border-violet-600" value={message.message || ''} placeholder='Habar...' onChange={handleChange}></textarea>
              </div>
              <div className="w-full">
                <button type='submit' disabled={isDisabled} ref={submitButton} className={'py-3 px-6 font-semibold bg-violet-600 text-white rounded-xl duration-300 ease-in-out hover:bg-violet-700 cursor-pointer ' + (isDisabled ? "opacity-50 pointer-events-none" : "")}>Jo'natish</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Forma