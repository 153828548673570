import React from 'react'
import { RiFacebookFill, RiInstagramFill, RiTelegramFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri'

function SocialMedia() {
  return (
    <ul className="flex gap-x-4">
      <li className="">
        <a href="/" className="inline-block p-2 bg-violet-50 text-gray-400 border border-violet-200 rounded-lg duration-300 ease-in-out hover:text-red-500"><RiYoutubeFill size={24} /></a>
      </li>
      <li className="">
        <a href="/" className="inline-block p-2 bg-violet-50 text-gray-400 border border-violet-200 rounded-lg duration-300 ease-in-out hover:text-pink-600"><RiInstagramFill size={24} /></a>
      </li>
      <li className="">
        <a href="/" className="inline-block p-2 bg-violet-50 text-gray-400 border border-violet-200 rounded-lg duration-300 ease-in-out hover:text-blue-500"><RiTelegramFill size={24} /></a>
      </li>
      <li className="">
        <a href="/" className="inline-block p-2 bg-violet-50 text-gray-400 border border-violet-200 rounded-lg duration-300 ease-in-out hover:text-blue-800"><RiFacebookFill size={24} /></a>
      </li>
      <li className="">
        <a href="/" className="inline-block p-2 bg-violet-50 text-gray-400 border border-violet-200 rounded-lg duration-300 ease-in-out hover:text-blue-500"><RiTwitterFill size={24} /></a>
      </li>
    </ul>
  )
}

export default SocialMedia