import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { RiMenuFill, RiCloseFill } from "react-icons/ri";

import Logo from './Logo';

function Header() {
  const [isMenu, setIsMenu] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  window.addEventListener('scroll', () => scrollFunction());

  const scrollFunction = () => {
    if (document.documentElement.scrollTop > 20) setIsScrolling(true);
    else setIsScrolling(false);
  }

  const menuOpen = () => {
    setIsMenu(true);

    return;
  }

  const menuClose = () => {
    setIsMenu(false);

    return;
  }

  return (
    <header className={"fixed w-full header py-4 z-10 backdrop-blur-lg " + (isScrolling ? "shadow" : "")} id="header">
      <div className="container">
        <div className="flex items-center justify-between">
          {/* header logo */}
          <div className="logo">
            <Link to='/'>
              <Logo />
            </Link>
          </div>
          {/* header navigation */}
          <ul className="hidden items-center gap-x-4 lg:flex">
            <li className="">
              <Link to='/' className='font-semibold duration-300 ease-in-out hover:text-violet-600'>Biz haqimizda</Link>
            </li>
            <li className="">
              <a href='/#forma' className='font-semibold duration-300 ease-in-out hover:text-violet-600'>Qanday ulanish mumkin?</a>
            </li>
            <li className="">
              <a href='/#contact' className='font-semibold duration-300 ease-in-out hover:text-violet-600'>Aloqa</a>
            </li>
          </ul>
          {/* mobile menu */}
          <div className={"block lg:hidden fixed w-full h-screen bg-black duration-300 ease-in-out top-0 left-0 z-10 " + (isMenu ? "opacity-50 pointer-events-auto" : "opacity-0 pointer-events-none")} onClick={menuClose}></div>
          <div className={"block lg:hidden fixed w-full max-w-md h-screen bg-white duration-300 ease-in-out top-0 z-10 " + (isMenu ? "right-0" : "-right-full")}>
            {/* mobile menu head */}
            <div className="w-full py-4 shadow-md">
              <div className="flex items-center justify-between px-4">
                <Logo />
                <button className='p-3 bg-violet-100 text-violet-800 rounded-lg duration-300 ease-in-out hover:bg-red-200 hover:text-red-800' onClick={menuClose}>
                  <RiCloseFill />
                </button>
              </div>
            </div>
            {/* mobile menu body */}
            <div className="py-4">
              <ul className="">
                <li className="">
                  <Link to='/' className='block py-2 px-4 font-semibold duration-300 ease-in-out hover:text-violet-600 hover:bg-violet-100' onClick={menuClose}>Biz haqimizda</Link>
                </li>
                <li className="">
                  <a href='/#forma' className='block py-2 px-4 font-semibold duration-300 ease-in-out hover:text-violet-600 hover:bg-violet-100' onClick={menuClose}>Qanday ulanish mumkin?</a>
                </li>
                <li className="">
                  <a href='/#contact' className='block py-2 px-4 font-semibold duration-300 ease-in-out hover:text-violet-600 hover:bg-violet-100' onClick={menuClose}>Aloqa</a>
                </li>
                {/* mobile menu body line */}
                <div className="pt-4 mt-4 border-t border-violet-100"></div>
                <li className="px-4 py-2 sm:hidden">
                  <a href='https://merchant.incard.uz/login' className='py-3 inline-block px-6 font-semibold bg-violet-600 text-white leading-4 rounded-xl duration-300 ease-in-out hover:bg-violet-700'>Kirish</a>
                </li>
              </ul>
            </div>
          </div>
          {/* header buttons */}
          <ul className="flex items-center gap-x-4">
            <li className="hidden sm:list-item">
              <a href='https://merchant.incard.uz/login' className='py-3 inline-block px-6 font-semibold bg-violet-600 text-white leading-4 rounded-xl duration-300 ease-in-out hover:bg-violet-700'>Kirish</a>
            </li>
            <li className="lg:hidden">
              <button className='p-3 bg-violet-100 text-violet-800 rounded-lg duration-300 ease-in-out hover:bg-violet-200' onClick={menuOpen}>
                <RiMenuFill />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header