import React from 'react';

import { FcDataProtection, FcDataConfiguration, FcAcceptDatabase } from "react-icons/fc";

import FeaturesCol from './FeaturesCol';

import Title from './Title';

function Features() {
  return (
    <section className='features py-24' id="features">
      <div className="container">
        {/* features title */}
        <div className="w-full" data-aos="fade-right">
          <Title text={"Bizning imkoniyatlarimiz"} position={"center"} />
        </div>

        {/* features row */}
        <div className="mt-0" data-aos="fade-right">
          {/* features row title */}
          <div className="w-full mb-8 flex items-start">
            <FcAcceptDatabase size={64} className='mr-4' />
            <h3 className='text-lg font-semibold lg:text-xl'>
              Qarzlarni so'ndirish uchun qulay bo'lgan raqamli yechim
            </h3>
          </div>

          <div className="flex flex-wrap gap-y-4 -mx-4">
            <FeaturesCol num={1} text={"O'zbekistonning barcha hududlari bo'ylab"} />
            <FeaturesCol num={2} text={"Barcha qarzi mavjud jismoniy shaxslar uchun"} />
            <FeaturesCol num={3} text={"Milliy valyutada (so'm)"} />
            <FeaturesCol num={4} text={"Xizmat ko'rsatish sifatining yuqoriligi"} />
          </div>
        </div>

        {/* features row */}
        <div className="mt-12" data-aos="fade-left">
          {/* features row title */}
          <div className="w-full mb-8 flex items-start">
            <FcDataProtection size={64} className='mr-4' />
            <h3 className='text-lg font-semibold lg:text-xl'>
              Barcha hamkorlarga individual yondashuv
            </h3>
          </div>

          <div className="flex flex-wrap gap-y-4 -mx-4">
            <FeaturesCol num={1} text={"Xizmat ko'rsatishda qulay bo'lgan tariflar"} />
            <FeaturesCol num={2} text={"Qarzlar va tranzaksiyalarni onlayn tarzda monitoring qilish"} />
            <FeaturesCol num={3} text={"Masofadan boshqarish imkoniyati"} />
            <FeaturesCol num={4} text={"Ortiqcha hujjatbozliklarsiz"} />
          </div>
        </div>

        {/* features row */}
        <div className="mt-12" data-aos="fade-left">
          {/* features row title */}
          <div className="w-full mb-8 flex items-start">
            <FcDataConfiguration size={64} className='mr-4' />
            <h3 className='text-lg font-semibold lg:text-xl'>
              Raqamli yechimning dasturiy- texnik imkoniyatlari
            </h3>
          </div>

          <div className="flex flex-wrap gap-y-4 -mx-4">
            <FeaturesCol num={1} text={"Tranzaksiya operatsiyalarining yuqori tezligi"} />
            <FeaturesCol num={2} text={"Tranzaksiya ma'lumotlarini avtomat tarzda qayta ishlash"} />
            <FeaturesCol num={3} text={"Xizmat ko'rsatishning masofaviy imkoniyati"} />
            <FeaturesCol num={4} text={"Xarzdorlikni so'ndirish bilan bog'liq bo'lgan vaqtni tejash"} />
            <FeaturesCol num={5} text={"Xarajatlarni qisqartirish"} />
            <FeaturesCol num={6} text={"HUMO/UzCARD bank kartalari orqali keng imkoniyat"} />
            <FeaturesCol num={7} text={"Axborot xavfsizligining yuqori darajada ekanligi"} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features