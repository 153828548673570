import React from 'react'
import Title from './Title'

import { RiMailLine, RiMapPin2Line, RiPhoneFill } from "react-icons/ri";
import SocialMedia from './SocialMedia';

function Contact() {
  const map_url = "https://yandex.ru/map-widget/v1/?um=constructor%3A1c6b67b203cf4aa71109db2aa5ce0b0e279feb5ba24bf65667934bc24c420187&source=constructor";

  return (
    <section className="contact pb-24" id="contact">
      <div className="w-full">
        <div className="flex flex-wrap gap-y-12 gap-x-4">
          {/* contact image */}
          <div className="w-full flex-auto lg:flex-1" data-aos="fade-right">
            <iframe src={map_url} className='w-full h-[280px] lg:h-[380px] rounded-tr-2xl rounded-br-2xl'></iframe>
          </div>
          {/* contact content */}
          <div className="w-full flex-auto lg:flex-1" data-aos="fade-left" data-aos-delay="250">
            <div className="container">
              {/* contact title */}
              <Title text={"Savollar bormi? Biz bilan bog'laning"} />
              {/* contact list */}
              <ul className="flex flex-wrap gap-y-4 mb-10">
                {/* contact phone */}
                <li className="w-full sm:w-1/2">
                  <h3 className='flex  items-center gap-x-2 text-lg font-semibold lg:text-xl'>
                    <RiPhoneFill className='text-violet-600' /> Telefon
                  </h3>
                  <a href='tel:+998901753525' className="pl-6 duration-300 ease-in-out hover:text-violet-600">+998 90 175 35 25</a>
                </li>
                {/* contact email */}
                <li className="w-full sm:w-1/2">
                  <h3 className='flex  items-center gap-x-2 text-lg font-semibold lg:text-xl'>
                    <RiMailLine className='text-violet-600' /> Email
                  </h3>
                  <a href='mailto:incardofficial@gmail.com' className="pl-6 duration-300 ease-in-out hover:text-violet-600">incardofficial@gmail.com</a>
                </li>
                {/* contact address */}
                <li className="w-full sm:w-1/2">
                  <h3 className='flex  items-center gap-x-2 text-lg font-semibold lg:text-xl'>
                    <RiMapPin2Line className='text-violet-600' /> Manzil
                  </h3>
                  <p className="pl-6">Toshkent shahar,</p>
                  <p className="pl-6">Yakkasaroy t., Qushbegi 18</p>
                </li>
                {/* contact working time */}
                <li className="w-full sm:w-1/2">
                  <h3 className='flex  items-center gap-x-2 text-lg font-semibold lg:text-xl'>
                    <RiMapPin2Line className='text-violet-600' /> Ish vaqti
                  </h3>
                  <p className="pl-6">Du-Ju <span className="ml-4">09:00-18:00</span></p>
                  <p className="pl-6">Sha-Ya <span className="ml-4">Dam olish kuni</span></p>
                </li>
              </ul>
              {/* contact social media */}
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact